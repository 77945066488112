import {
  FETCH_VOUCHER,
  FAILED_VOUCHER,
  UPDATE_STATE_VOUCHER
} from '../../actions/types'

const initialState = {
  loading: false,
  listVendorFisik: [],
  listVendorDigital: [],
  listVoucher: [],
  listVoucherHistory: [],
  voucherDetail: {},
  errorMessage: null
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_VOUCHER:
      return { ...state, loading: true }
    case FAILED_VOUCHER:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.error
      }
    case UPDATE_STATE_VOUCHER: {
      return { ...state, ...action.payload }
    }
    default:
      return state
  }
}
