import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import changePasswordStore from './auth/changePasswordStore'
import userStore from './userStore'
import loginStore from './auth/loginStore'
import authStore from './auth/authStore'
import resetPasswordStore from './auth/resetPasswordStore'
import registerStore from './auth/registerStore'
import registerVerificationStore from './auth/registerVerificationStore'
import resendVerificationStore from './auth/resendVerificationStore'
import verificationStore from './auth/verificationStore'

import homeStore from './home/homeStore'
import voucherStore from './home/voucherStore'

export default combineReducers({
  form: formReducer,
  changePasswordStore,
  userStore,
  authStore,
  loginStore,
  resetPasswordStore,
  registerStore,
  registerVerificationStore,
  resendVerificationStore,
  verificationStore,

  homeStore,
  voucherStore
})
