const statusColor = (value) => {
  switch (Number(value)) {
    case 1:
      return '#FEBF35'
    case 2:
      return '#00DED6'
    default:
      return '#000000'
  }
}

export default {
  // Start - Material Pallete https://www.materialpalette.com/light-green/light-blue
  primaryColor: '#89C73B',
  secondaryColor: '#02D3C8',
  contentColor: '#cafaf7',
  darkPrimaryColor: '#347D00',
  lightPrimaryColor: '#DCEDC8',
  grayButton: '#EBEBEB',
  grayButtonText: '#909090',
  grayBgColor: '#707070',
  textIcons: '#ffffff',
  titleColor: '#00A6A0',
  accentIcons: '#03A9F4',
  danger: '#F05555',
  primaryText: '#212121',
  secondaryText: '#757575',
  dividerColor: '#BDBDBD',
  backgroundColor: '#FAFCFB',
  darkTextColor: '#000000',
  // End - Material Pallete https://www.materialpalette.com/light-green/light-blue
  normalColor: '#B1B0B0',
  // icon on #00DED6
  // iconOff: '#B9B9B9',
  iconOff: '#BCBCBC',
  logout: '#FF6767',
  login: '#51DD8C',
  // icon color #333333
  white: '#ffffff',
  errorColor: '#E4202D',
  starColor: '#FEBF35',
  transparent: 'transparent',
  link: '#03A9F4',
  gray: '#ACACAC',
  softGray: '#5A5858',
  orange: '#FF4E00',
  facebook: '#4266b2',
  google: '#EC3F7A',
  boxShadow: `
    0px 0px 4px 2px rgba(0,0,0,0.14),
    0px 0px 7px -5px rgba(0,0,0,0.12)
    `,
  cardShadow: `
  0px 0px 1px 0px rgba(0,0,0,0.2),
  0px 1px 1px 0px rgba(0,0,0,0.14),
  0px 7px 7px -1px rgba(0,0,0,0.12)
    `,
  statusColor
}
