// USER
export const FETCH_USER = 'FETCH_USER'
export const RECEIVE_USER = 'RECEIVE_USER'
export const RECEIVE_ITEM_USER = 'RECEIVE_ITEM_USER'
export const RESET_ITEM_USER = 'RESET_ITEM_USER'
export const SUCCESS_USER = 'SUCCESS_USER'
export const UPDATE_STATE_USER = 'UPDATE_STATE_USER'
export const FAILED_USER = 'FAILED_USER'

// AUTH
export const FETCH_AUTH = 'FETCH_AUTH'
export const RECEIVE_ITEM_AUTH = 'RECEIVE_ITEM_AUTH'
export const FAILED_AUTH = 'FAILED_AUTH'

// IMAGE
export const FETCH_IMAGE = 'FETCH_IMAGE'
export const SUCCESS_IMAGE = 'SUCCESS_IMAGE'
export const FAILED_IMAGE = 'FAILED_IMAGE'

// SEARCH
export const FETCH_SEARCH = 'FETCH_SEARCH'
export const RECEIVE_SEARCH = 'RECEIVE_SEARCH'
export const RECEIVE_ITEM_SEARCH = 'RECEIVE_ITEM_SEARCH'
export const FAILED_SEARCH = 'FAILED_SEARCH'
export const UPDATE_STATE_SEARCH = 'UPDATE_STATE_SEARCH'

// LOGIN
export const FETCH_LOGIN = 'FETCH_LOGIN'
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN'
export const FAILED_LOGIN = 'FAILED_LOGIN'

// REGISTER
export const FETCH_REGISTER = 'FETCH_REGISTER'
export const RECEIVE_REGISTER = 'RECEIVE_REGISTER'
export const FAILED_REGISTER = 'FAILED_REGISTER'

// FORGOT PASSWORD
export const FETCH_FORGOT_PASSWORD = 'FETCH_FORGOT_PASSWORD'
export const RECEIVE_ITEM_FORGOT_PASSWORD = 'RECEIVE_ITEM_FORGOT_PASSWORD'
export const FAILED_FORGOT_PASSWORD = 'FAILED_FORGOT_PASSWORD'

// CHANGE PASSWORD
export const FETCH_CHANGE_PASSWORD = 'FETCH_CHANGE_PASSWORD'
export const RECEIVE_ITEM_CHANGE_PASSWORD = 'RECEIVE_ITEM_CHANGE_PASSWORD'
export const FAILED_CHANGE_PASSWORD = 'FAILED_CHANGE_PASSWORD'

// VERIFICATION
export const FETCH_VERIFICATION = 'FETCH_VERIFICATION'
export const SUCCESS_VERIFICATION = 'SUCCESS_VERIFICATION'
export const FAILED_VERIFICATION = 'FAILED_VERIFICATION'

// RESET PASSWORD
export const FETCH_RESET_PASSWORD = 'FETCH_RESET_PASSWORD'
export const SUCCESS_RESET_PASSWORD = 'SUCCESS_RESET_PASSWORD'
export const FAILED_RESET_PASSWORD = 'FAILED_RESET_PASSWORD'

// REGISTER VERIFICATION
export const FETCH_REGISTER_VERIFICATION = 'FETCH_REGISTER_VERIFICATION'
export const RECEIVE_REGISTER_VERIFICATION = 'RECEIVE_REGISTER_VERIFICATION'
export const FAILED_REGISTER_VERIFICATION = 'FAILED_REGISTER_VERIFICATION'

// RESEND VERIFICATION
export const FETCH_RESEND_VERIFICATION = 'FETCH_RESEND_VERIFICATION'
export const RECEIVE_RESEND_VERIFICATION = 'RECEIVE_RESEND_VERIFICATION'
export const FAILED_RESEND_VERIFICATION = 'FAILED_RESEND_VERIFICATION'

// HOME
export const FETCH_HOME = 'FETCH_HOME'
export const RECEIVE_HOME = 'RECEIVE_HOME'
export const RECEIVE_LIST_HOME = 'RECEIVE_LIST_HOME'
export const FAILED_HOME = 'FAILED_HOME'
export const ADD_LIST_HOME = 'ADD_LIST_HOME'
export const UPDATE_STATE_HOME = 'UPDATE_STATE_HOME'

// VOUCHER
export const FETCH_VOUCHER = 'FETCH_VOUCHER'
export const FAILED_VOUCHER = 'FAILED_VOUCHER'
export const UPDATE_STATE_VOUCHER = 'UPDATE_STATE_VOUCHER'
